// Copyright (C) 2017-2023 Quasi, Inc. All Rights Reserved.

import {createSlice, createAsyncThunk, isAnyOf} from '@reduxjs/toolkit'

import TaskType from '../../../services/TaskType'

export const tasktypesList = createAsyncThunk('tasktypes/list', async (params) => {
  const response = await TaskType.list({params})
  return {
    params,
    data: response.data.taskTypes,
    total: response.data.total
  }
})

export const loadTaskType = createAsyncThunk('tasktypes/load', async id => {
  const response = await new TaskType(id).get()
  return response.data
})

export const createTaskType = createAsyncThunk('tasktypes/create', async (tasktype, {dispatch, getState, rejectWithValue}) => {
  try {
    const res = await TaskType.create(tasktype)
    await dispatch(tasktypesList(getState().tasktypes.params))
    return res.data
  } catch (err) {
    console.error('createTaskType error:', err.response)
    return rejectWithValue(err.response?.data?.msg)
  }
})

export const updateTaskType = createAsyncThunk('tasks/update', async (tasktype, {dispatch, getState, rejectWithValue}) => {
  try {
    //console.log('tasktype', tasktype)
    const {ttypeId, ...params} = tasktype
    //console.log('ttaskId', ttypeId)
    const res = await new TaskType(ttypeId).update(params)
    await dispatch(tasktypesList(getState().tasktypes.params))
    //console.log('updateRobot res:', res.data)
    return res.data
  } catch (err) {
    console.error('updateTaskType error:', err, err.response)
    return rejectWithValue(err.response?.data?.msg)
  }
})


export const tasktypesSlice = createSlice({
  name: 'tasktypes',
  initialState: {
    data: [],
    total: 0,
    params: {},
    selectedTaskType: null,
    loading: false,
    selectedLoading: false,
    editCreateLoading: false
  },
  reducers: {
    clearSelection: (state) => {
      state.selectedTaskType = null
    }
  },
  extraReducers: builder => {
    builder
      .addCase(tasktypesList.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.total
        state.loading = false
      })
      .addCase(tasktypesList.pending, (state) => {
        state.loading = true
      })
      .addCase(tasktypesList.rejected, (state) => {
        state.loading = false
      })
      .addCase(loadTaskType.fulfilled, (state, action) => {
        state.selectedTaskType = action.payload
        state.selectedLoading = false
      })
      .addCase(loadTaskType.rejected, (state) => {
        state.selectedLoading = false
      })
      .addCase(loadTaskType.pending, (state) => {
        state.selectedLoading = true
      })
      .addCase(updateTaskType.fulfilled, (state, action) => {
        //console.log('pdateRobot.fulfilled', action)
        state.selectedTaskType = action.payload
      })
      .addMatcher(isAnyOf(
        updateTaskType.pending,
        createTaskType.pending
      ), (state) => {
        state.editCreateLoading = true
      })
      .addMatcher(isAnyOf(
        updateTaskType.fulfilled,
        updateTaskType.rejected,
        createTaskType.fulfilled,
        createTaskType.rejected
      ), (state) => {
        state.editCreateLoading = false
      })
  }
})
export const {
  clearSelection
} = tasktypesSlice.actions

export default tasktypesSlice.reducer
