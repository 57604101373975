// Copyright (C) 2017-2023 Quasi, Inc. All Rights Reserved.

import {createSlice, createAsyncThunk, isAnyOf} from '@reduxjs/toolkit'

import Schedule from '../../../services/Schedule'

export const serviceScheduleList = createAsyncThunk('service-schedule/list', async (params) => {
  const {...query} = params
  const response = await Schedule.list({params: query})
  return {
    params,
    data: response.data.serviceSchedule,
    total: response.data.total
  }
})

export const createServiceSchedule = createAsyncThunk('service-schedule', async (params, {dispatch, getState}) => {
  try {
    await Schedule.create(params)
    await dispatch(serviceScheduleList(getState().serviceschedule?.params))
    // return res.data
  } catch (err) {
    return console.log(err)
  }
})

export const getServiceSchedule = createAsyncThunk('service-schedule/load', async ({id}) => {
    const response = await new Schedule(id).get()
    return {
        data: response.data
    }
})

export const updateServiceSchedule = createAsyncThunk('service-schedule/update', async (serviceSchedule, {dispatch, getState}) => {
  try {
      const {id, ...params} = serviceSchedule
      const res = await new Schedule(id).update(params)
    await dispatch(serviceScheduleList(getState().serviceschedule?.params))
    return res.data
  } catch (err) {
    return console.log(err)
  }
})

export const deleteServiceSchedule = createAsyncThunk('service-schedule', async ({id}, {dispatch, getState}) => {
  try {
    await new Schedule(id).delete()
    await dispatch(serviceScheduleList(getState().serviceschedule?.params))
    // return res.data
  } catch (err) {
    return console.log(err)
  }
})


export const serviceScheduleSlice = createSlice({
  name: 'serviceschedule',
  initialState: {
    data: [],
    total: 0,
    params: {},
      selectedServiceSchedule: null,
      selectedLoading: false,
    loading: false
  },
  extraReducers: builder => {
    builder
        .addCase(serviceScheduleList.fulfilled, (state, action) => {
          state.data = action.payload.data
          state.params = action.payload.params
          state.total = action.payload.total
          state.loading = false
        })
        .addCase(serviceScheduleList.pending, (state) => {
            state.loading = true
        })
        .addCase(serviceScheduleList.rejected, (state) => {
            state.loading = false
        })
        .addCase(getServiceSchedule.fulfilled, (state, action) => {
            console.log(action.payload.data)
            state.selectedServiceSchedule = action.payload.data
            state.selectedLoading = false
        })
        .addCase(getServiceSchedule.rejected, (state) => {
            state.selectedLoading = false
        })
        .addCase(getServiceSchedule.pending, (state) => {
            state.selectedLoading = true
        })
        .addCase(updateServiceSchedule.fulfilled, (state, action) => {
            state.selectedServiceSchedule = action.payload
        })
  }
})

export default serviceScheduleSlice.reducer
