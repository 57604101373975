// Copyright (C) 2017-2023 Quasi, Inc. All Rights Reserved.
import {createAsyncThunk, createSlice, isAnyOf} from "@reduxjs/toolkit"
import Environment from "../../../services/Environment"
import ChargingStation from "../../../services/ChargingStation"
import Company from "../../../services/Company"


export const stationList = createAsyncThunk('stations/list', async (params) => {
  const {envId, companyId, ...query} = params
  const srv = envId ? new Environment(envId) : companyId ? new Company(companyId) : ChargingStation
  const res = await srv.chargingStations(query)

  return {
    params,
    data: res.data.chargingStations,
    total: res.data.total
  }
})

export const loadStation = createAsyncThunk('stations/load', async (id) => {
  const res = await new ChargingStation(id).get()
  return res.data
})

export const createStation = createAsyncThunk('stations/create', async (station, {
  dispatch,
  getState,
  rejectWithValue
}) => {
  try {
    const {envId, ...params} = station
    const res = await new Environment(envId).createStation(params)

    await dispatch(stationList(getState().chargingStation.params))
    return res.data
  } catch (err) {
    return rejectWithValue(err.response?.data?.msg)
  }
})

export const updateStation = createAsyncThunk('stations/update', async (station, {
  dispatch,
  getState,
  rejectWithValue
}) => {
  try {
    const {id, ...params} = station
    const res = await new ChargingStation(id).update(params)

    await dispatch(stationList(getState().chargingStation.params))
    return res.data
  } catch (err) {
    rejectWithValue(err.response?.data?.msg)
  }
})

export const stationSlice = createSlice({
  name: 'stations',
  initialState: {
    data: [],
    total: 0,
    params: {},
    selectedStation: null,
    selectedLoading: false,
    loading: false,
    editCreateLoading: false
  },
  reducers: {
    clearSelection: state => {
      state.selectedStation = null
    }
  },
  extraReducers: builder => {
    builder
      .addCase(stationList.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.total
      })
      .addCase(loadStation.fulfilled, (state, action) => {
        state.selectedLoading = false
        state.selectedStation = action.payload
      })
      .addCase(loadStation.pending, (state) => {
        state.selectedLoading = true
      })
      .addCase(stationList.pending, (state) => {
        state.loading = true
      })
      .addMatcher(isAnyOf(
        createStation.pending,
        updateStation.pending
      ), (state) => {
        state.editCreateLoading = true
      })
      .addMatcher(isAnyOf(
        createStation.rejected,
        createStation.fulfilled,
        updateStation.rejected,
        updateStation.fulfilled
      ), (state, action) => {
        state.editCreateLoading = false
        state.selectedStation = action.payload
      })
      .addMatcher(isAnyOf(
        loadStation.rejected,
        loadStation.fulfilled,
        stationList.rejected,
        stationList.fulfilled
      ), (state) => {
        state.loading = false
        state.selectedLoading = false
      })
  }
})

export const { clearSelection } = stationSlice.actions

export default stationSlice.reducer
