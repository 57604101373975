// Copyright (C) 2017-2023 Quasi, Inc. All Rights Reserved.

// ** Core JWT Import

const api = '/api/v1'
const jwtSconfig = {
  loginEndpoint: `${api}/auth/login`,
  registerEndpoint: '/jwt/register',
  refreshEndpoint: `${api}/auth/refresh-token`,
  logoutEndpoint: `${api}/auth/logout`,
  forgotPassword: `${api}/auth/forgot-password`,
  resetPassword: `${api}/auth/reset-password`,

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken'
}

import JwtService from './jwtService'

// ** Export Service as useJwt
function useJwt() {
  const jwt = new JwtService(jwtSconfig)

  return {
    jwt
  }
}

const { jwt } = useJwt()

export default jwt
