// Copyright (C) 2017-2023 Quasi, Inc. All Rights Reserved.

import {BaseService} from './index'
import axios from "axios"

export default class TaskTemplate extends BaseService {
  static collection = 'tasktemplates'

  static list(props) {
    const {status, ...rest} = props
    const config = this.constructConfig(rest)
    return axios.get(this.constructUrl(status), config)
  }

  static taskTemplates(props) {
    return this.list(props)
  }

  deleteTaskTemplate(params) {
    return axios.delete(this.constructUrl(''), params)
  }
}