// Copyright (C) 2017-2023 Quasi, Inc. All Rights Reserved.

import React, {useEffect, useState, forwardRef, useMemo} from 'react'
import ReactPaginate from "react-paginate"

import {Col, Row, Input, Button, Label, Spinner, Card} from 'reactstrap'
import Flatpickr from "react-flatpickr"
import classnames from 'classnames'

import AsyncSelect, { StylesConfig } from 'react-select/async'
import Select from "react-select"

import Company from '../../services/Company'
import Robot from '../../services/Robot'
import Environment from '../../services/Environment'
import Workspace from "../../services/Workspace"
import TaskType from '../../services/TaskType'
import User from '../../services/User'
import TaskTemplate from "../../services/TaskTemplate"
import ChargingStation from "../../services/ChargingStation"
import {HiOutlineRefresh} from "react-icons/hi"
import {scrollToRef} from "../../utility/Utils"

import '@styles/react/libs/flatpickr/flatpickr.scss'

export const Pagination = (props) => {
  const {rowsPerPage, rowCount, onChangePage, onChangeRowsPerPage, currentPage} = props
  //console.log(props)
  const count = Math.ceil((rowCount) / rowsPerPage)
  const forcePage = currentPage > count ? count : currentPage
//  console.log(count, forcePage, props)
  return (
    <div className='d-flex justify-content-end pe-1 mt-50'>
      <div className='d-flex align-items-center me-2'>
        <label htmlFor='rows-per-page'>Show</label>
        <Input
          type='select'
          id='rows-per-page'
          value={rowsPerPage}
          onChange={onChangeRowsPerPage}
          className='form-control pe-3 mx-50'
        >
          <option value='5'>5</option>
          <option value='10'>10</option>
          <option value='20'>20</option>
        </Input>
        <label htmlFor='rows-per-page'>Entries</label>
      </div>
      <ReactPaginate
        previousLabel={''}
        nextLabel={''}
        breakLabel='...'
        pageCount={Math.ceil(count) || 1}
        marginPagesDisplayed={2}
        pageRangeDisplayed={2}
        activeClassName='active'
        forcePage={forcePage !== 0 ? forcePage - 1 : 0}
        onPageChange={page => onChangePage(page)}
        pageClassName='page-item'
        breakClassName='page-item'
        nextLinkClassName='page-link'
        pageLinkClassName='page-link'
        breakLinkClassName='page-link'
        previousLinkClassName='page-link'
        nextClassName='page-item next-item'
        previousClassName='page-item prev-item'
        containerClassName={
          'pagination react-paginate separated-pagination pagination-sm mt-50'
        }
      />
    </div>
  )
}

export const CompanySelect = forwardRef((props, ref) => {
  const promiseOptions = () => {
    return new Promise((resolve) => {
      if (props.isDisabled) resolve([])
      Company.list({forSelect: true}).then(response => {
        //console.log(response.data)
        const options = response.data.companies.map(c => ({value: c._id, label: c.name}))
        resolve(options)
      })
    })
  }
  const {invalid, className, value, ...rest} = props
  return (
    <AsyncSelect
      ref={ref}
      {...rest}
      className={classnames({
        [className]: className,
        'is-invalid': invalid
      })}
      value={value}
      defaultOptions
      loadOptions={promiseOptions}
    />
  )
})

export const TaskTypeSelect = forwardRef((props, ref) => {
  const promiseOptions = () => {
    return new Promise((resolve) => {
      TaskType.list({forSelect: true}).then(response => {
        //console.log(response.data)
        const options = response.data.taskTypes.map(c => ({value: c._id, label: c.name}))
        resolve(options)
      })
    })
  }
  const {invalid, className, ...rest} = props
  return (
    <AsyncSelect
      ref={ref}
      {...rest}
      className={classnames({
        [className]: className,
        'is-invalid': invalid
      })}
      defaultOptions
      loadOptions={promiseOptions}
    />
  )
})

export const EnvironmentSelect = forwardRef((props, ref) => {
  const [companyId, setCompanyId] = useState(null)

  useEffect(() => {
    setCompanyId(props.companyId)
  }, [props.companyId])

  const promiseOptions = () => {
    return new Promise((resolve) => {
      if (!companyId) {
        resolve([])
      } else {
        new Company(companyId).environments({forSelect: true}).then(response => {
          //console.log(response.data)
          const options = response.data.environments.map(c => ({value: c._id, label: c.name}))
          resolve(options)
        }).catch(e => {
          console.error(e)
          resolve([])
        })
      }
    })
  }

  const {invalid, className, control, onChange, ...rest} = props

  return (
    <AsyncSelect
      ref={ref}
      {...rest}
      onChange={onChange}
      className={classnames({
        [className]: className,
        'is-invalid': invalid
      })}
      key={`__env_select_for__${JSON.stringify(companyId)}`}
      defaultOptions
      loadOptions={promiseOptions}
    />
  )
})

export const WorkspaceSelect = forwardRef((props, ref) => {
  const [envId, setEnvId] = useState(null)

  useEffect(() => {
    setEnvId(props.envId)
  }, [props.envId])

  const promiseOptions = () => {
    return new Promise((resolve) => {
      if (!envId) {
        resolve([])
      } else {
        new Environment(envId).workspaces({forSelect: true}).then(response => {
          //console.log(response.data)
          const options = response.data.workspaces.map(c => ({value: c._id, label: c.name}))
          resolve(options)
        }).catch(e => {
          console.error(e)
          resolve([])
        })
      }
    })
  }

  const {invalid, className, control, onChange, value, ...rest} = props

  return (
    <AsyncSelect
      ref={ref}
      {...rest}
      value={value}
      onChange={onChange}
      className={classnames({
        [className]: className,
        'is-invalid': invalid
      })}
      key={`__ws_select_for__${JSON.stringify(envId)}`}
      defaultOptions
      loadOptions={promiseOptions}
    />
  )
})

export const ItemSelect = forwardRef((props, ref) => {
  const [companyId, setCompanyId] = useState(null)

  useEffect(() => {
    setCompanyId(props.companyId)
  }, [props.companyId])

  const promiseOptions = () => {
    return new Promise((resolve) => {
      if (!companyId) {
        resolve([])
      } else {
        new Company(companyId).items({forSelect: true}).then(response => {
          //console.log(response.data)
          const options = response.data.items.map(c => ({value: c._id, label: c.name}))
          resolve(options)
        }).catch(e => {
          console.error(e)
          resolve([])
        })
      }
    })
  }
  const {invalid, className, onChange, value, ...rest} = props

  return (
    <AsyncSelect
      ref={ref}
      {...rest}
      className={classnames({
        [className]: className,
        'is-invalid': invalid
      })}
      value={value}
      key={`__item_select_for__${JSON.stringify(companyId)}`}
      defaultOptions
      onChange={onChange}
      loadOptions={promiseOptions}
    />
  )
})

export const RobotMultiSelect = forwardRef((props, ref) => {
  const [companyId, setCompanyId] = useState(null)
  const [envId, setEnvId] = useState(null)
  const {invalid, className, onChange, value, isUserPermission = false, ...rest} = props

  useEffect(() => {
    setCompanyId(props.companyId)
    setEnvId(props.envId)
  }, [props.companyId, props.envId])

  const promiseOptions = () => {
    return new Promise((resolve) => {
      const srv = companyId ? new Company(companyId) : (envId ? new Environment(envId) : !isUserPermission ? Robot : resolve([]))
      srv.robots({forSelect: true}).then(response => {
        // console.log(response.data)
        const options = response.data.robots.map(c => ({value: c._id, label: c.serialNumber}))
        resolve(options)
      }).catch(e => {
        console.error(e)
        resolve([])
      })
    })
  }

  return (
      <AsyncSelect
          ref={ref}
          {...rest}
          className={classnames({
            [className]: className,
            'is-invalid': invalid
          })}
          value={value}
          key={`__robot_select_for__${JSON.stringify(companyId || envId)}`}
          defaultOptions
          closeMenuOnSelect={false}
          isMulti
          onChange={onChange}
          loadOptions={promiseOptions}
          styles={{
            multiValue: (provided, state) => {
              const backgroundColor = '#7367f0 !important'

              return { ...provided, backgroundColor }
            },
            multiValueLabel: (provided, state) => {
              const color = '#ffffff !important'

              return { ...provided, color }
            },
            multiValueRemove: (provided, state) => {
              const color = '#ffffff !important'

              return { ...provided, color }
            }
          }}
      />
  )
})

export const RobotSelect = forwardRef((props, ref) => {
  const {invalid, className, onChange, value, companyId, envId, isUserPermission = false, ...rest} = props

  const promiseOptions = () => {
    return new Promise((resolve) => {
      // console.log('companyId', companyId)
      const srv = companyId ? new Company(companyId) : (envId ? new Environment(envId) : !isUserPermission ? Robot : resolve([]))
      srv.robots({forSelect: true}).then(response => {
        //console.log(response.data)
        const options = response.data.robots.map(c => ({value: c._id, label: c.serialNumber}))
        resolve(options)
      }).catch(e => {
        console.error(e)
        resolve([])
      })
    })
  }

  return (
    <AsyncSelect
      ref={ref}
      {...rest}
      className={classnames({
        [className]: className,
        'is-invalid': invalid
      })}
      value={value}
      key={`__robot_select_for__${JSON.stringify(companyId || envId)}`}
      defaultOptions
      onChange={onChange}
      loadOptions={promiseOptions}
    />
  )
})

export const TaskStatusOptions = [
  {value: 'all', label: 'All'},
  {value: 'pending', label: 'Pending'},
  {value: 'assigned', label: 'Assigned'},
  {value: 'in-progress', label: 'In Progress'},
  {value: 'completed', label: 'Completed'},
  {value: 'failed', label: 'Failed'},
  {value: 'req-cancel', label: 'Cancel Requested'},
  {value: 'canceled', label: 'Canceled'}
]

export const TaskStatusSelect = forwardRef((props, ref) => {
  return (
    <Select {...props} ref={ref} options={TaskStatusOptions}/>
  )
})

export const TaskFilteredStatusSelect = forwardRef((props, ref) => {
  const {invalid = false, className} = props
  return (
    <Select
      {...props}
      className={classnames({
        [className]: className,
        'is-invalid': invalid
      })}
      ref={ref}
      options={TaskStatusOptions}
    />
  )
})

export const PriorityOptions = [
  {value: 1, label: 'Low'},
  {value: 2, label: 'Normal'},
  {value: 3, label: 'High'}
]

export const PriorityStatusSelect = forwardRef((props, ref) => {
  return (
    <Select {...props} ref={ref} options={PriorityOptions}/>
  )
})

export const RefreshButton = forwardRef((props, ref) => {
  const {onClick, listRef = null, loading = false} = props

  const handleClick = () => {
    onClick()
    if (listRef && listRef.current) {
      scrollToRef(listRef)
    }
  }

  return (
    <HiOutlineRefresh
        title="Refresh"
      onClick={handleClick}
      ref={ref}
      disabled={loading}
      className="position-absolute zindex-4 bottom-20 left-20 cursor-pointer"
      size="2em"
    />
  )
})


export const DatePicker = forwardRef((props, ref) => {
  const {onChange, invalid = false, className, options = {}} = props

  return (
    <Flatpickr
      {...props}
      className={classnames({
        'is-invalid': invalid,
        "form-control": true
      })}
      onChange={date => onChange(date[0])}
      placeholder="Select Date"
      ref={ref}
      options={{
        dateFormat: 'M d Y',
        ...options
      }}
    />
  )
})

export const UserSelect = forwardRef((props, ref) => {
  const [companyId, setCompanyId] = useState(null)

  const promiseOption = () => {
    return new Promise((resolve) => {

      const srv = companyId ? new Company(companyId) : User
      srv.list({forSelect: true})
        .then(response => {
          const options = response.data?.users.map(user => ({
            value: user._id,
            label: `${user.fullname} (${user.username})`
          }))
          //console.log(response.data)
          resolve(options)
        })
        .catch(() => {
          resolve([])
        })
    })
  }

  useEffect(() => {
    setCompanyId(props.companyId)
  }, [props.companyId])

  const {className, onChange, invalid = false, ...rest} = props

  return (
    <AsyncSelect
      {...rest}
      ref={ref}
      className={classnames({
        [className]: className,
        'is-invalid': invalid
      })}
      defaultOptions
      key={`_user_select_for${companyId}`}
      loadOptions={promiseOption}
      onChange={onChange}
    />
  )
})

export const FormatOption = [
  {value: 'xlsx', label: 'Excel'},
  {value: 'csv', label: 'CSV'}
]

export const FormatPicker = forwardRef((props, ref) => {
  const {invalid = false, className} = props
  return (
    <Select
      {...props}
      className={classnames({
        [className]: className,
        'is-invalid': invalid
      })}
      ref={ref}
      options={FormatOption}
    />
  )
})

export const SelectTaskTemplate = forwardRef((props, ref) => {
  const {className, invalid = false, onChange, companyId, wholeObject = false, ...rest} = props
  const promiseOption = () => {
    return new Promise((resolve) => {
      const srv = companyId ? new Company(companyId) : TaskTemplate
      const apiCall = companyId ? srv.taskTemplates({forSelect: !!wholeObject}) : srv.list({forSelect: !!wholeObject})
      apiCall
        .then(res => {
          const options = res.data?.taskTemplates.map(el => ({
            value: !wholeObject ? el._id : el,
            label: el.name
          }))
          resolve(options)
        })
        .catch(() => {
          resolve([])
        })
    })
  }

  return (
    <AsyncSelect
      {...rest}
      ref={ref}
      className={classnames({
        [className]: className,
        'is-invalid': invalid
      })}
      defaultOptions
      key={`_template_select_for${companyId}`}
      loadOptions={promiseOption}
      onChange={onChange}
    />
  )
})

export const SelectChargingStation = forwardRef((props, ref) => {
  const {className, invalid = false, onChange, envId, companyId, ...rest} = props
  const promiseOption = () => {
    return new Promise((resolve) => {
      const srv = envId ? new Environment(envId) : companyId ? new Company(companyId) : ChargingStation
      const apiCall = srv.chargingStations()
      apiCall
        .then(res => {
          const options = res.data?.chargingStations.map(el => ({
            value: el._id,
            label: el.serialNumber
          }))
          resolve(options)
        })
        .catch(() => {
          resolve([])
        })
    })
  }
  return (
    <AsyncSelect
      {...rest}
      ref={ref}
      className={classnames({
        [className]: className,
        'is-invalid': invalid
      })}
      defaultOptions
      key={`_station_select_for${envId}`}
      loadOptions={promiseOption}
      onChange={onChange}
    />
  )
})

export const RequestCategoryOption = [
  {value: 'Unscheduled', label: 'Unscheduled'},
  {value: 'Scheduled', label: 'Scheduled'}
]

export const RequestCategorySelect = forwardRef((props, ref) => {
  const {invalid = false, className} = props
    return (
        <Select
            {...props}
            className={classnames({
              [className]: className,
              'is-invalid': invalid
            })}
            ref={ref}
            options={RequestCategoryOption}
        />
    )
  })

export const GripperPressureOptions = [
  { value: 1, label: 'Very Low' },
  { value: 2, label: 'Low' },
  { value: 3, label: 'Medium' },
  { value: 4, label: 'High' },
  { value: 5, label: 'Very High' }
]

export const GripperPressureSelect = forwardRef((props, ref) => {
  const { invalid, className } = props
  return (
    <Select
      ref={ref}
      {...props}
      className={classnames({
        [className]: className,
        'is-invalid': invalid
      })}
      options={GripperPressureOptions}
    />
  )
})

export const WorkspaceAreaSelect = forwardRef((props, ref) => {
  const [wsId, setWsId] = useState(null)

  useEffect(() => {
    setWsId(props.wsId)
  }, [props.wsId])

  const promiseOptions = () => {
    return new Promise((resolve) => {

      if (!wsId) {
        resolve([])
      } else {
        new Workspace(wsId).get().then(response => {
          const options = (response.data.areas || []).map(a => ({value: a, label: a.name}))
          resolve(options)
        }).catch(e => {
          console.error(e)
          resolve([])
        })
      }
    })
  }

  const {invalid, className, control, onChange, value, ...rest} = props

  return (
    <AsyncSelect
      ref={ref}
      {...rest}
      value={value}
      onChange={onChange}
      className={classnames({
        [className]: className,
        'is-invalid': invalid
      })}
      key={`__workspace_area_select_for__${JSON.stringify(wsId)}`}
      defaultOptions
      loadOptions={promiseOptions}
    />
  )
})
