
// Copyright (C) 2017-2023 Quasi, Inc. All Rights Reserved.

import {BaseService} from './index'

export default class Item extends BaseService {
  static collection = 'items'

  static items(props) {
    return this.list(props)
  }
}