// Copyright (C) 2017-2023 Quasi, Inc. All Rights Reserved.

import {BaseService} from './index'
import axios from "axios"

export default class Task extends BaseService {
  static collection = 'tasks'

  static list(props) {
    const config = this.constructConfig(props)
    return axios.get(this.constructUrl(), config)
  }

  static tasks(props) {
    return this.list(props)
  }

  snapshots(params) {
    return axios.get(this.constructUrl('snapshots'), params)
  }

}