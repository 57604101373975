// Copyright (C) 2017-2023 Quasi, Inc. All Rights Reserved.

import { createBrowserHistory } from 'history'
import { store } from '@store/store'

// ** Store & Actions
import { handleLogout } from '@store/authentication'

import axios from 'axios'

export default class JwtService {
  // ** jwtConfig <= Will be used by this service
  jwtConfig = {}

  // ** For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // ** For Refreshing Token
  subscribers = []

  constructor(jwtOverrideConfig) {
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // ** Request Interceptor
    axios.interceptors.request.use(
      config => {
        // ** Get token from localStorage
        const accessToken = this.getToken()

        // ** If token is present add it to request's Authorization Header
        if (accessToken) {
          // ** eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        return config
      },
      error => Promise.reject(error)
    )
    axios.defaults.withCredentials = true

    // ** Add request/response interceptor
    axios.interceptors.response.use(
      response => response,
      error => {
        // ** const { config, response: { status } } = error
        const { config, response } = error
        const originalRequest = config

        // ** if (status === 401) {
        if (response && response.status === 401) {
          if (config.url === this.jwtConfig.loginEndpoint || config.url === this.jwtConfig.refreshEndpoint) {
            // don't need refreshToken for login
            return Promise.reject(error)
          }
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true
            this.refreshToken().then(r => {
              this.isAlreadyFetchingAccessToken = false

              // ** Update accessToken in localStorage
              this.setToken(r.data.accessToken)

              this.onAccessTokenFetched(r.data.accessToken)
            }).catch(e => {
              this.isAlreadyFetchingAccessToken = false
              this.subscribers = []
              this.logout().then(r => {
                store.dispatch(handleLogout())
                createBrowserHistory().push('/')
                window.location.reload()              
              })
            })
          }
          const retryOriginalRequest = new Promise(resolve => {
            this.addSubscriber(accessToken => {
              // ** Make sure to assign accessToken according to your response.
              // ** Check: https://pixinvent.ticksy.com/ticket/2413870
              // ** Change Authorization header
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
              resolve(axios(originalRequest))
            })
          })
          return retryOriginalRequest
        }
        return Promise.reject(error)
      }
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    const token = localStorage.getItem(this.jwtConfig.storageTokenKeyName)
    //console.log('getToken', token)
    return token
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  login(...args) {
    const ret =  axios.post(this.jwtConfig.loginEndpoint, ...args)
    //console.log(ret)
    return ret
  }

  logout(...args) {
    return axios.get(this.jwtConfig.logoutEndpoint, ...args)
  }

  register(...args) {
    return axios.post(this.jwtConfig.registerEndpoint, ...args)
  }

  refreshToken() {
    return axios.get(this.jwtConfig.refreshEndpoint, {})
  }

  forgotPassword(...args) {
    return axios.post(this.jwtConfig.forgotPassword, ...args)
  }

  resetPassword(...args) {
    return axios.post(this.jwtConfig.resetPassword, ...args)
  }

}
