
// Copyright (C) 2017-2023 Quasi, Inc. All Rights Reserved.

import axios from 'axios'

import {BaseService} from './index'

export default class User extends BaseService {
  static collection = 'users'

  static users(props) {
    return this.list(props)
  }

  static updateMe(params) {
    return axios.patch(this.constructUrl('update'), params)
  }

  static updatePassword(params) {
    return axios.patch(this.constructUrl('updatePassword'), params)
  }

  updatePassword(params) {
    return axios.patch(this.constructUrl('updatePassword'), params)
  }
  deleteUser(params) {
    return axios.delete(this.constructUrl(''), params)
  }

  getSettings(params) {
    return axios.get(this.constructUrl('settings'), params)
  }

  getSettingsOptions(params) {
    return axios.get(this.constructUrl(['settings', 'options']), params)
  }

  setSettings(params) {
    return axios.patch(this.constructUrl('settings'), params)
  }

  setNotificationSettings(params) {
    return axios.patch(this.constructUrl(['settings', 'notification']), params)
  }

  getAllHistory(params) {
    return axios.get(this.constructUrl(['history']), { params })
  }

}
