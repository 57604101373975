// Copyright (C) 2017-2023 Quasi, Inc. All Rights Reserved.

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import Company from '../../../services/Company'

export const companiesList = createAsyncThunk('companies/list', async params => {
  //console.log(params)
  const response = await Company.list({params})
//  console.log(response.data)
  return {
    params,
    data: response.data.companies,
    total: response.data.total
  }
})

export const loadCompany = createAsyncThunk('companies/load', async id => {
  const api = new Company(id)
  const response = await api.get()
  try {
    const res1 = await api.logo()
    response.data.logo = res1
  } catch (err) {

  }
  return response.data
})

export const createCompany = createAsyncThunk('companies/create', async (company, { dispatch, getState, rejectWithValue }) => {
  try {
    const res = await Company.create(company)
    await dispatch(companiesList(getState().companies.params))
    return res.data
  } catch (err) {
//    console.log('addUser error:', err.response)
    return rejectWithValue(err.response?.data?.msg)
  }
})

export const updateCompany = createAsyncThunk('companies/update', async (company, { dispatch, getState, rejectWithValue }) => {
  try {
    const {companyId, ...params} = company
    const res = await new Company(companyId).update(params)
    //await dispatch(getData(getState().users.params))
    //console.log('updateRobot res:', res.data)
    return res.data
  } catch (err) {
    console.error('updateCompany error:', err.response)
    return rejectWithValue(err.response?.data?.msg)
  }
})

export const uploadDetectionModel = createAsyncThunk('companies/uploadModel', async (
  {
    companyId,
    file,
    onStart,
    onProgress,
    onFinish
  },
  {
    dispatch,
    getState,
    rejectWithValue
  }) => {
    try {
      const res = await new Company(companyId).uploadDetectionModel({file, onStart, onProgress, onFinish})      
      return res.data
    } catch (err) { 
      console.error(err)
      return rejectWithValue(err.response?.data?.msg) 
    }
})

export const downloadDetectionModel = createAsyncThunk('companies/downloadModel', async (
  {
    companyId,
    onStart,
    onProgress,
    onFinish
  },
  {
    dispatch,
    getState,
    rejectWithValue
  }) => {
    try {
      await new Company(companyId).downloadDetectionModel({onStart, onProgress, onFinish})
    } catch (err) {
      console.error(err)
      return rejectWithValue(err.response?.data?.msg)
    }
})

export const companiesSlice = createSlice({
  name: 'companies',
  initialState: {
    data: [],
    total: 0,
    params: {},
    selectedCompany: null,
    listLoading: false,
    selectedLoading: false
  },
  reducers: {
    clearSelection: (state) => {
      state.selectedCompany = null
    }
  },
  extraReducers: builder => {
    builder
      .addCase(companiesList.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.total
        state.listLoading = false
      })
      .addCase(companiesList.pending, (state) => {
        state.listLoading = true
      })
      .addCase(companiesList.rejected, (state) => {
        state.listLoading = false
      })
      .addCase(loadCompany.fulfilled, (state, action) => {
        state.selectedCompany = action.payload
        state.selectedLoading = false
      })
      .addCase(loadCompany.pending, (state) => {
        state.selectedLoading = true
      })
      .addCase(loadCompany.rejected, (state) => {
        state.selectedLoading = false
      })
      .addCase(updateCompany.fulfilled, (state, action) => {
       //console.log('pdateRobot.fulfilled', action)
       state.selectedCompany = action.payload
      })
      .addCase(uploadDetectionModel.fulfilled, (state, action) => {
        state.selectedCompany = action.payload
      })
}
})
export const {
  clearSelection
} = companiesSlice.actions

export default companiesSlice.reducer
