// Copyright (C) 2017-2023 Quasi, Inc. All Rights Reserved.
import { BaseService } from "./index"
import axios from "axios"

export default class ChargingStation extends BaseService {
  static collection = 'chargingStation'

  static chargingStations(params) {
    return this.list({params})
  }

  chargingEvents(params) {
    return axios.get(this.constructUrl('chargingEvents'), {params})
  }
  static chargingEvents(params) {
    return axios.get(this.constructUrl(['chargingEvents', 'all']), {params})
  }

  getMapData(params) {
    return axios.get(this.constructUrl('mapData'), {params})
  }
}
