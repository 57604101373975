// Copyright (C) 2017-2023 Quasi, Inc. All Rights Reserved.

import {createSlice, createAsyncThunk, isAnyOf} from '@reduxjs/toolkit'

import RobotModel from '../../../services/RobotModel'

export const robotmodelsList = createAsyncThunk('robot-models/list', async (params) => {
  const response = await RobotModel.list({params})
  return {
    params,
    data: response.data.list,
    total: response.data.total
  }
})

// export const loadModel = createAsyncThunk('robotmodels/load', async id => {
//   const response = await new Robot(id).get()
//   return response.data
// })

export const createRobotModel = createAsyncThunk('robot-models/create', async (model, { dispatch, getState, rejectWithValue }) => {
  try {
    const res = await RobotModel.create(model)
    await dispatch(robotmodelsList(getState().robotmodels.params))
    return res.data
  } catch (err) {
    console.error('createModel error:', err.response)
    return rejectWithValue(err.response?.data?.msg)
  }
})

export const updateRobotModel = createAsyncThunk('robot-models/update', async (model, { dispatch, getState, rejectWithValue }) => {
  try {
    const {modelId, ...params} = model
    const res = await new RobotModel(modelId).update(params)
    await dispatch(robotmodelsList(getState().robotmodels.params))
    //console.log('updateRobot res:', res.data)
    return res.data
  } catch (err) {
    console.error('createModel error:', err.response)
    return rejectWithValue(err.response?.data?.msg)
  }
})


export const robotmodelsSlice = createSlice({
  name: 'robotmodels',
  initialState: {
    data: [],
    total: 0,
    params: {},
    loading: false
    // selectedLoading: false,
    // editCreateLoading: false
  },
  reducers: {
    clearSelection: (state) => {
      state.selectedTaskType = null
    }
  },
  extraReducers: builder => {
    builder
      .addCase(robotmodelsList.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.total
        state.loading = false
      })
      .addCase(robotmodelsList.pending, (state) => {
        state.loading = true
      })
      .addCase(robotmodelsList.rejected, (state) => {
        state.loading = false
      })
      // .addCase(loadTaskType.fulfilled, (state, action) => {
      //   state.selectedTaskType = action.payload
      //   state.selectedLoading = false
      // })
      // .addCase(loadTaskType.rejected, (state) => {
      //   state.selectedLoading = false
      // })
      // .addCase(loadTaskType.pending, (state) => {
      //   state.selectedLoading = true
      // })
      // .addCase(updateTaskType.fulfilled, (state, action) => {
      //   //console.log('pdateRobot.fulfilled', action)
      //   state.selectedTaskType = action.payload
      // })
      // .addMatcher(isAnyOf(
      //   updateTaskType.pending,
      //   createTaskType.pending
      // ), (state) => {
      //   state.editCreateLoading = true
      // })
      // .addMatcher(isAnyOf(
      //   updateTaskType.fulfilled,
      //   updateTaskType.rejected,
      //   createTaskType.fulfilled,
      //   createTaskType.rejected
      // ), (state) => {
      //   state.editCreateLoading = false
      // })
  }
})
export const {
  clearSelection
} = robotmodelsSlice.actions

export default robotmodelsSlice.reducer
