// Copyright (C) 2017-2023 Quasi, Inc. All Rights Reserved.

import {createSlice, createAsyncThunk, isAnyOf} from '@reduxjs/toolkit'

import Task from '../../../services/Task'
import Company from '../../../services/Company'
import Robot from '../../../services/Robot'
import Workspace from "../../../services/Workspace"

export const tasksList = createAsyncThunk('tasks/list', async (params) => {
  const {companyId, robotId, status, wsId, ...query} = params
  const srv =  robotId ? new Robot(robotId) : (companyId ? new Company(companyId) : wsId ? new Workspace(wsId) : Task)
//  console.log(params, query)
  const response = await srv.tasks({params: query, status})
  return {
    params,
    data: response.data.tasks,
    total: response.data.total
  }
})

export const loadTask = createAsyncThunk('tasks/load', async id => {
  const response = await new Task(id).get()
  return response.data
})

export const createTask = createAsyncThunk('tasks/create', async (task, {dispatch, getState, rejectWithValue}) => {
  try {
    const res = task?.company ? await new Company(task.company).createTask(task) : await Task.create(task)

    await dispatch(tasksList(getState().tasks.params))
    return res.data
  } catch (err) {
    console.error('createTask error:', err)
    return rejectWithValue(err.response?.data?.msg)
  }
})

export const updateTask = createAsyncThunk('tasks/update', async (task, {dispatch, getState, rejectWithValue}) => {
  try {
//    console.log('task', task)
    const {taskId, ...params} = task
    const res = await new Task(taskId).update(params)
    await dispatch(tasksList(getState().tasks.params))
    //console.log('updateRobot res:', res.data)
    return res.data
  } catch (err) {
    console.error('updateTask error:', err, err.response)
    return rejectWithValue(err.response?.data?.msg)
  }
})

export const getTaskHistory = createAsyncThunk('task/history', async ({id, ...params}, { rejectWithValue }) => {
  try {
    const res = await new Task(id).history(params)
    //console.log(res)
    return {
      data: res.data.history,
      total: res.data.total
    }
  } catch (err) {
    return rejectWithValue(err.response?.data?.msg)
  }
})


export const tasksSlice = createSlice({
  name: 'tasks',
  initialState: {
    data: [],
    total: 0,
    params: {},
    selectedTask: null,
    loading: false,
    selectedLoading: false,
    editCreateLoading: false,
    history: {
      data: [],
      total: 0,
      loading: false
    }
  },
  reducers: {
    clearSelection: (state) => {
      state.selectedTask = null
    }
  },
  extraReducers: builder => {
    builder
      .addCase(tasksList.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.total
        state.loading = false
      })
      .addCase(tasksList.pending, (state) => {
        state.loading = true
      })
      .addCase(tasksList.rejected, (state) => {
        state.loading = false
      })
      .addCase(loadTask.fulfilled, (state, action) => {
        state.selectedTask = action.payload
        state.selectedLoading = false
      })
      .addCase(loadTask.rejected, (state) => {
        state.selectedLoading = false
      })
      .addCase(loadTask.pending, (state) => {
        state.selectedLoading = true
      })
      .addCase(updateTask.fulfilled, (state, action) => {
        //console.log('pdateRobot.fulfilled', action)
        state.selectedTask = action.payload
      })
      .addCase(getTaskHistory.pending, (state) => {
        state.history.loading = true
      })
      .addCase(getTaskHistory.fulfilled, (state, action) => {
        state.history.loading = false
        state.history.data = action.payload.data
        state.history.total = action.payload.total
        //console.log(action)
      })
      .addCase(getTaskHistory.rejected, (state) => {
        state.history.loading = false
      })
      .addMatcher(isAnyOf(
        updateTask.pending,
        createTask.pending
      ), (state) => {
        state.editCreateLoading = true
      })
      .addMatcher(isAnyOf(
        updateTask.fulfilled,
        updateTask.rejected,
        createTask.fulfilled,
        createTask.rejected
      ), (state) => {
        state.editCreateLoading = false
      })
  }
})
export const {
  clearSelection
} = tasksSlice.actions

export default tasksSlice.reducer
