// Copyright (C) 2017-2023 Quasi, Inc. All Rights Reserved.

import {BaseService} from './index'
import axios from 'axios'
import { fakeApiData } from "../utility/Utils"

export default class Company extends BaseService {
  static collection = 'companies'

  environments(props) {
    const config = this.constructor.constructConfig(props)
    return axios.get(this.constructUrl('environments'), config)
  }
  items(props) {
    const config = this.constructor.constructConfig(props)
    return axios.get(this.constructUrl('items'), config)
  }
  robots(props) {
    const config = this.constructor.constructConfig(props)
    return axios.get(this.constructUrl('robots'), config)
  }
  tasks(props) {
    const {status, ...rest} = props
    const config = this.constructor.constructConfig(rest)
    return axios.get(this.constructUrl('tasks'), config)
  }
  users(props) {
    const config = this.constructor.constructConfig(props)
    return axios.get(this.constructUrl('users'), config)
  }
  workspaces(props) {
    const config = this.constructor.constructConfig(props)
    return axios.get(this.constructUrl('workspaces'), config)
  }
  objects(props) {
    const config = this.constructor.constructConfig(props)
    return axios.get(this.constructUrl('objects'), config)
  }

  tasksDashPoints(params) {
    const config = this.constructor.constructConfig({params})
    return axios.get(this.constructUrl('tasksDashPoints'), config)
  }

  tasksWeeklyStats(params) {
    const config = this.constructor.constructConfig({params})
    return axios.get(this.constructUrl('tasksWeeklyStats'), config)
  }

  createTask(params) {
    return axios.post(this.constructUrl('tasks'), params)
  }

  getTimeAllocation(params) {
    return axios.get(this.constructUrl('timeAllocation'), {params})
  }

  getChargingPoints(params) {
    return axios.get(this.constructUrl('chargingEventsDash'), { params })
  }

  getDistancePoints(params) {
    return axios.get(this.constructUrl('distanceDash'), {params})
  }

  getChargingBatteryDash(params) {
    return axios.get(this.constructUrl('chargingEventBatteryDash'), {params})
  }

  taskTemplates(params) {
    const config = this.constructor.constructConfig({params})
    return axios.get(this.constructUrl('tasktemplates'), config)
  }

  createTaskTemplate(params) {
    return axios.post(this.constructUrl('tasktemplates'), params)
  }

  createEnvironment(params) {
    return axios.post(this.constructUrl('environments'), params)
  }

  getAllHistory(params) {
    const config = this.constructor.constructConfig({params})
    return axios.get(this.constructUrl('allHistory'), config)
  }

  chargingEvents(params) {
    return axios.get(this.constructUrl('chargingEvents'), {params})
  }

  getServiceRequests(params) {
    return axios.get(this.constructUrl('serviceRequests'), {params})
  }

  chargingStations(params) {
    return axios.get(this.constructUrl('chargingStations'), {params})
  }

  movementChunks(params) {
    return axios.get(this.constructUrl('movementChunk'), {params})
  }

  getProfit(params) {
    return axios.get(this.constructUrl('profit'), {params})
  }

  async uploadDetectionModel(props) {
    const method = 'detectionModel'
    return this.uploadFile({...props, method})
  }

  async downloadDetectionModel(props) {
    const filename = `company_${this.id}.model`
    const method = 'detectionModel'
    this.downloadFile({...props, filename, method})
  }

  async logo() {
    const response = await axios.get(this.constructUrl('logo'), {
      responseType: "arraybuffer"
    })
    return {data: response.data, type: response.headers['content-type']}
  }

  async saveLogo(file) {
    return await axios.patch(this.constructUrl('logo'), file, {
      headers: {
        'Content-Type': file.type
      }
    })
  }

  async downloadReport({url, query, filename}) {
    return await axios.get(this.constructUrl(`report/${url}`), {
      responseType: "blob",
      params: query
    })
      .then((res) => {
        const url = window.URL.createObjectURL(
          new Blob([res.data], {
            type: res.headers["content-type"]
          })
        )

        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", filename)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        return ''
      })
      .catch((error) => {
        return error.response.statusText
      })
  }
}
