// Copyright (C) 2017-2023 Quasi, Inc. All Rights Reserved.
import crypto from 'crypto'
import * as dayjs from 'dayjs'
import {GripperPressureOptions} from "../views/components"

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = obj => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}
// export const formatDateTime = (value) => {
//   if (!value) return value
//
//   return dayjs(value).format('MMM D YYYY, hh:mm:ss A')
// }


/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem('userData')
export const getUserData = () => JSON.parse(localStorage.getItem('userData'))
export const isMe = (user) => {
  const currUser = getUserData()
  return currUser && user && currUser._id === user._id
}
export const amIAdmin = () => {
  const currUser = getUserData()
  return currUser?.role === 'admin'
}

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'admin') return '/'
  if (userRole === 'client') return '/access-control'
  return '/login'
}

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
})

export const randomString = (length, chars) => {
  if (!chars) {
    throw new Error('Argument \'chars\' is undefined')
  }

  const charsLength = chars.length
  if (charsLength > 256) {
    throw new Error('Argument \'chars\' should not have more than 256 characters'
      + ', otherwise unpredictability will be broken')
  }

  const randomBytes = crypto.randomBytes(length)
  const result = new Array(length)

  let cursor = 0
  for (let i = 0; i < length; i++) {
    cursor += randomBytes[i]
    result[i] = chars[cursor % charsLength]
  }

  return result.join('')
}

export const randomAsciiString = (length) => {
  return randomString(length,
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789')
}

export function debounce(func, timeout = 300) {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      // eslint-disable-next-line no-invalid-this
      func.apply(this, args)
    }, timeout)
  }
}

export const scrollToRef = (ref) => {
  window.scrollTo({ top: ref.current.offsetTop - 70 })
}

export const fakeApiData = (data) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => resolve({data}), 5000)
  })
}

export const camelCaseToCapitalCase = (str) => {
  return str.replace(/([A-Z])/g, ' $1').replace(/^./, s => s.toUpperCase())
}

const DateFormat = {
  ymd: 'Y-m-d',
  jmy: 'j-M-Y',
  dmy: 'd.m.Y',
  mdy: 'm/d/Y'
}
DateFormat.default = DateFormat.mdy
DateFormat.All = Object.values(DateFormat)

export const TimeFormat = {
  His: 'H:i:s',
  Hisu: 'H:i:s.u',
  hisA: 'h:i:s: A'
}
TimeFormat.default = TimeFormat.hisA
TimeFormat.All = Object.values(TimeFormat)

export const pad = value => {
  return value < 10 ? `0${value}` : value
}

const constructDate = (date, part) => {
  if (!date) return ''
  switch (part) {
    case 'm':
      return pad(date.getMonth() + 1)
    case 'M':
      return date.toLocaleString('default', { month: 'short' })
    case 'd':
      return pad(date.getDate())
    case 'Y':
      return date.getFullYear()
    case 'j':
      return date.getDate()
    default:
      return null
  }
}

export const formatTime = (date, format) => {
  if (!date) return ''
  date = new Date(date)
  switch (format) {
    case TimeFormat.His:
      return `${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(date.getSeconds())}`
    case TimeFormat.Hisu:
      return `${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(date.getSeconds())}.${date.getMilliseconds()}`
    case TimeFormat.hisA:
      return `${pad(date.getHours() % 12 || 12)}:${pad(date.getMinutes())}:${pad(date.getSeconds())} ${date.getHours() >= 12 ? 'PM' : 'AM'}`
  }
}

export const formatDate = (date, dateFormat = DateFormat.mdy) => {
  if (!date) return
  date = new Date(date)
  const dateSeparator = ['-', '/', '.'].find(sep => dateFormat.includes(sep))
  const dateParts = dateFormat.split(dateSeparator)
  return dateParts.map(part => constructDate(date, part)).join(dateSeparator)
}

export const formatDateTime = (date, dateFormat, timeFormat) => {
  if (!date) return
  if (!DateFormat.All.includes(dateFormat)) dateFormat = DateFormat.default
  if (!TimeFormat.All.includes(timeFormat)) timeFormat = TimeFormat.default
  date = new Date(date)
  const dateSeparator = ['-', '/', '.'].find(sep => dateFormat.includes(sep))
  const dateParts = dateFormat.split(dateSeparator)
  const resultDate = dateParts.map(part => constructDate(date, part)).join(dateSeparator)
  return `${resultDate} ${formatTime(date, timeFormat)}`
}

// weight in oz
export const formatWeight = (weight, measurementUnit) => {
  return measurementUnit === 'Imperial' ? `${weight.toFixed(2)}oz` : `${(weight * 28.34952).toFixed(2)}g`
}

export const getGripperPressure = val => {
  if (!val) return GripperPressureOptions[0]
  return GripperPressureOptions.find(e => e.value === val)
}

export const objectIdRegex = new RegExp("^[0-9a-fA-F]{24}$")

export function toTitleCase (str) {
  return str.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1)
  })
}

export const splitCamelCase = s => s.split(/(?=[A-Z])/).join(' ')

export const METER_TO_FEET = 3.28084

export const convertMeterToFeet = (val, settings) => (settings === 'Metric' ? `${val.toFixed(2)} m` : `${(val * METER_TO_FEET).toFixed(2)} ft`)

export const weekDays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
//
// export const urlData = (base64Image) => {
//   const data = Buffer.from(base64Image?.data || [], "binary").toString("base64")
//   return `data:${base64Image?.type};base64,${data}`
// }

export const secondsToTime = (seconds) => {
  const hours = Math.floor(seconds / (60 * 60))
  seconds -= hours * 60 * 60
  const minutes = Math.floor(seconds / 60)
  seconds -= minutes * 60
  return `${hours ? `${hours}h` : ''} ${minutes ? `${minutes}m` : ''} ${seconds ? `${Math.floor(seconds)}s` : !hours && !minutes ? '0' : ''}`
}
