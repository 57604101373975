
// Copyright (C) 2017-2023 Quasi, Inc. All Rights Reserved.


import {BaseService} from './index'
import axios from "axios"

export default class Workspace extends BaseService {
  static collection = 'workspaces'

  static workspaces(props) {
    return this.list(props)
  }

  getMapData(params) {
    return axios.get(this.constructUrl('mapData'), {params})
  }

  tasks(props) {
    const {status, ...rest} = props
    const config = this.constructor.constructConfig(rest)
    return axios.get(this.constructUrl('tasks'), config)
  }
}
