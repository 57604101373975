// Copyright (C) 2017-2023 Quasi, Inc. All Rights Reserved.

import {createSlice, createAsyncThunk, isAnyOf} from '@reduxjs/toolkit'

import Workspace from '../../../services/Workspace'
import Environment from '../../../services/Environment'
import Company from "../../../services/Company"

export const workspacesList = createAsyncThunk('workspaces/list', async (params) => {
//  console.log(params)
  const {envId, companyId, ...query} = params
  const srv = envId ? new Environment(envId) : companyId ? new Company(companyId) : Workspace
  const response = await srv.workspaces({params: query})
//  console.log(response.data)
  return {
    params,
    data: response.data.workspaces,
    total: response.data.total
  }
})

export const loadWorkspace = createAsyncThunk('workspaces/load', async id => {
  const response = await new Workspace(id).get()
  return response.data
})

export const createWorkspace = createAsyncThunk('workspaces/create', async (workspace, { dispatch, getState, rejectWithValue }) => {
  try {
    const { envId } = workspace
    const res = envId ? await new Environment(envId).createWorkspace(workspace) : await Workspace.create(workspace)
    await dispatch(workspacesList(getState().workspaces.params))
    return res.data
  } catch (err) {
    console.error('createWorkspace error:', err.response)
    return rejectWithValue(err.response?.data?.msg)
  }
})

export const updateWorkspace = createAsyncThunk('workspaces/update', async (environment, { dispatch, getState, rejectWithValue }) => {
  try {
    const {wsId, ...params} = environment
    const res = await new Workspace(wsId).update(params)
    await dispatch(workspacesList(getState().workspaces.params))
    return res.data
  } catch (err) {
    console.error('updateworkspace error:', err.response)
    return rejectWithValue(err.response?.data?.msg)
  }
})

export const workspacesSlice = createSlice({
  name: 'workspaces',
  initialState: {
    data: [],
    total: 0,
    params: {},
    selectedWorkspace: null,
    loading: false,
    editCreateLoading: false
  },
  reducers: {
    clearSelection: (state) => {
      state.selectedWorkspace = null
    }
  },
  extraReducers: builder => {
    builder
      .addCase(workspacesList.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.total
        state.loading = false
      })
      .addCase(loadWorkspace.fulfilled, (state, action) => {
        state.selectedWorkspace = action.payload
        state.loading = false
      })
      .addCase(updateWorkspace.fulfilled, (state, action) => {
       //console.log('pdateRobot.fulfilled', action)
       state.selectedWorkspace = action.payload
     })
      .addMatcher(isAnyOf(
        workspacesList.rejected,
        loadWorkspace.rejected
      ), (state) => {
        state.loading = false
      })
      .addMatcher(isAnyOf(
        workspacesList.pending,
        loadWorkspace.pending
      ), (state) => {
        state.loading = true
      })
      .addMatcher(isAnyOf(
        createWorkspace.pending,
        updateWorkspace.pending
      ), (state) => {
        state.editCreateLoading = true
      })
      .addMatcher(isAnyOf(
        updateWorkspace.fulfilled,
        updateWorkspace.rejected,
        createWorkspace.fulfilled,
        createWorkspace.rejected
      ), (state) => {
        state.editCreateLoading = false
      })
}
})
export const {
  clearSelection
} = workspacesSlice.actions

export default workspacesSlice.reducer
