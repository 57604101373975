// Copyright (C) 2017-2023 Quasi, Inc. All Rights Reserved.

import {createSlice, createAsyncThunk, isAnyOf} from '@reduxjs/toolkit'

import TaskTemplate from '../../../services/TaskTemplate'
import Company from '../../../services/Company'
import Robot from '../../../services/Robot'

export const taskTemplatesList = createAsyncThunk('tasktemplates/list', async (params) => {
  const {companyId, status, robotId, ...query} = params
  const srv =  companyId ? new Company(companyId) : (robotId ? new Robot(robotId) : TaskTemplate)
  const response = await srv.taskTemplates({params: query, status})
  return {
    params,
    data: response.data.taskTemplates,
    total: response.data.total
  }
})

export const loadTaskTemplate = createAsyncThunk('tasktemplates/load', async id => {
  const response = await new TaskTemplate(id).get()
  return response.data
})

export const createTaskTemplate = createAsyncThunk('tasktemplates/create', async ({ companyId, ...tasktemplate }, {dispatch, getState, rejectWithValue}) => {
  try {
    const res = companyId ? await new Company(companyId).createTaskTemplate(tasktemplate) : await TaskTemplate.create(tasktemplate)

    await dispatch(taskTemplatesList(getState().tasktemplate?.params))
    return res.data
  } catch (err) {
    console.error('createTaskTemplate error:', err)
    return rejectWithValue(err.response?.data?.msg)
  }
})

export const updateTaskTemplate = createAsyncThunk('tasktemplates/update', async (tasktemplate, {dispatch, getState, rejectWithValue}) => {
  try {
//    console.log('tasktemplate', tasktemplate)
    const {taskTemplateId, ...params} = tasktemplate
    const res = await new TaskTemplate(taskTemplateId).update(params)

    await dispatch(taskTemplatesList(getState().tasktemplate?.params))
    return res.data
  } catch (err) {
    console.error('updateTaskTemplate error:', err, err.response)
    return rejectWithValue(err.response?.data?.msg)
  }
})


export const taskTemplatesSlice = createSlice({
  name: 'tasktemplates',
  initialState: {
    data: [],
    total: 0,
    params: {},
    selectedTaskTemplate: null,
    loading: false,
    selectedLoading: false,
    editCreateLoading: false
  },
  reducers: {
    clearSelection: (state) => {
      state.selectedTaskTemplate = null
    }
  },
  extraReducers: builder => {
    builder
      .addCase(taskTemplatesList.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.total
        state.loading = false
      })
      .addCase(taskTemplatesList.pending, (state) => {
        state.loading = true
      })
      .addCase(taskTemplatesList.rejected, (state) => {
        state.loading = false
      })
      .addCase(loadTaskTemplate.fulfilled, (state, action) => {
        state.selectedTaskTemplate = action.payload
        state.selectedLoading = false
      })
      .addCase(loadTaskTemplate.rejected, (state) => {
        state.selectedLoading = false
      })
      .addCase(loadTaskTemplate.pending, (state) => {
        state.selectedLoading = true
      })
      .addCase(updateTaskTemplate.fulfilled, (state, action) => {
        //console.log('pdateRobot.fulfilled', action)
        state.selectedTaskTemplate = action.payload
      })
      .addMatcher(isAnyOf(
        updateTaskTemplate.pending,
        createTaskTemplate.pending
      ), (state) => {
        state.editCreateLoading = true
      })
      .addMatcher(isAnyOf(
        updateTaskTemplate.fulfilled,
        updateTaskTemplate.rejected,
        createTaskTemplate.fulfilled,
        createTaskTemplate.rejected
      ), (state) => {
        state.editCreateLoading = false
      })
  }
})
export const {
  clearSelection
} = taskTemplatesSlice.actions

export default taskTemplatesSlice.reducer
