// Copyright (C) 2017-2023 Quasi, Inc. All Rights Reserved.

// ** Redux Imports
import {createSlice, createAsyncThunk, isAnyOf} from '@reduxjs/toolkit'

import User from '../../../services/User'
import Company from '../../../services/Company'

// ** Axios Imports
import axios from 'axios'

export const usersList = createAsyncThunk('users/list', async params => {
  //console.log(params)
  const {companyId, ...query} = params
  const srv = companyId ? new Company(companyId) : User
  const response = await srv.users({params: query})
//  console.log(response.data)
  return {
    params,
    data: response.data.users,
    total: response.data.total
  }
})

export const loadUser = createAsyncThunk('users/load', async (id, { rejectWithValue }) => {
  const response = await new User(id).get()
  return response.data
})

export const createUser = createAsyncThunk('users/create', async (user, { dispatch, getState, rejectWithValue }) => {
  try {
    const res = await User.create(user)
    await dispatch(usersList(getState().users.params))
    return res.data
  } catch (err) {
//    console.log('addUser error:', err.response)
    return rejectWithValue(err.response?.data?.msg)
  }
})

export const updatePasswordAdmin = createAsyncThunk('users/updatePassAdmin', async ({id, newPassword}, {rejectWithValue}) => {
  try {
    return await new User(id).updatePassword({newPassword})
  } catch (err) {
    return rejectWithValue(err.response?.data?.msg)
  }
})

export const updatePasswordUser = createAsyncThunk('/users/updatePass', async (data, { rejectWithValue }) => {
  try {
    return await User.updatePassword(data)
  } catch (err) {
//    console.log(err, err.response)
    return rejectWithValue(err.response?.data?.msg)
  }
})

export const updateUserAdmin = createAsyncThunk('users/updateAdmin', async (user, { dispatch, getState, rejectWithValue }) => {
  try {
    const {userId, ...params} = user
    const res = await new User(userId).update(params)
    await dispatch(usersList(getState().users.params))
    return res.data
  } catch (err) {
//    console.log('updateUserAdmin error:', err)
    return rejectWithValue(err.response?.data?.msg)
  }
})

export const updateUser = createAsyncThunk('users/updateMe', async (user, { dispatch, getState, rejectWithValue }) => {
  try {
    const res = await User.updateMe(user)
    await dispatch(usersList(getState().users.params))
    return res.data
  } catch (err) {
    console.error('updateUser error:', err)
    return rejectWithValue(err.response?.data?.msg)
  }
})

// export const deleteUser = createAsyncThunk('appUsers/deleteUser', async (id, { dispatch, getState, rejectWithValue }) => {
//   await axios.delete('/apps/users/delete', { id })
//   await dispatch(usersList(getState().users.params))
//   return id
// })

export const appUsersSlice = createSlice({
  name: 'users',
  initialState: {
    data: [],
    total: 0,
    params: {},
    selectedUser: null,
    selectedLoading: false,
    selectedError: null,
    loading: false,
    editCreateLoading: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(usersList.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.total
        state.loading = false
      })
      .addCase(usersList.pending, (state) => {
        state.loading = true
      })
      .addCase(usersList.rejected, (state) => {
        state.loading = false
      })
      .addCase(loadUser.pending, (state) => {
        state.selectedLoading = true
      })
      .addCase(loadUser.rejected, (state, action) => {
        state.selectedLoading = false
      })
      .addCase(loadUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
        state.selectedLoading = false
      })
      .addMatcher(isAnyOf(
          updateUserAdmin.pending,
          updateUser.pending,
          createUser.pending,
          updatePasswordUser.pending,
          updatePasswordAdmin.pending
      ), (state) => {
        state.editCreateLoading = true
      })
      .addMatcher(isAnyOf(updateUserAdmin.fulfilled, updateUser.fulfilled, createUser.fulfilled), (state, action) => {
        state.editCreateLoading = false
        state.selectedUser = action.payload
      })
      .addMatcher(isAnyOf(updateUserAdmin.rejected, updateUser.rejected, createUser.rejected), (state) => {
        state.editCreateLoading = false
      })
      .addMatcher(isAnyOf(
          updatePasswordAdmin.fulfilled,
          updatePasswordAdmin.rejected,
          updatePasswordUser.fulfilled,
          updatePasswordUser.rejected
      ), (state) => {
        state.editCreateLoading = false
      })
  }
})

export default appUsersSlice.reducer

