// Copyright (C) 2017-2023 Quasi, Inc. All Rights Reserved.

import {BaseService} from './index'
import axios from "axios"

export default class ServerLog extends BaseService {
  static collection = 'logger'

  static list(props) {
    const config = this.constructConfig(props)
    return axios.get(this.constructUrl(), config)
  }
}
