// Copyright (C) 2017-2023 Quasi, Inc. All Rights Reserved.
import {BaseService} from "./index"
import axios from "axios"

export default class MovementChunk extends BaseService {
  static collection = 'movementChunk'

  static movementChunks(params) {
    return this.list({params})
  }

  getPositions(params) {
    return axios.get(this.constructUrl('positions'), {params})
  }

  getMapData(params) {
    return axios.get(this.constructUrl('mapData'), {params})
  }

}
