
// Copyright (C) 2017-2023 Quasi, Inc. All Rights Reserved.

import {BaseService} from './index'
import axios from 'axios'

export default class Environment extends BaseService {
  static collection = 'environments'

  workspaces(props) {
    const config = this.constructor.constructConfig(props)
    return axios.get(this.constructUrl('workspaces'), config)
  }
  robots(props) {
    const config = this.constructor.constructConfig(props)
    return axios.get(this.constructUrl('robots'), config)
  }

  chargingStations(props) {
    const config = this.constructor.constructConfig(props)
    return axios.get(this.constructUrl('chargingStation'), config)
  }

  createStation(props) {
    return axios.post(this.constructUrl('chargingStation'), props)
  }

  createWorkspace(props) {
    return axios.post(this.constructUrl('workspaces'), props)
  }

  movementChunks(params) {
    return axios.get(this.constructUrl('movementChunk'), {params})
  }

  getMapData(params) {
    return axios.get(this.constructUrl('mapData'), {params})
  }

  static environments(props) {
    return this.list(props)
  }

  async uploadMap(props) {
    const method = 'mapFile'
    return this.uploadFile({...props, method})
  }

  async downloadMap(props) {
    const filename = `env_${this.id}_map.tar.xz`
    const method = 'mapFile'
    this.downloadFile({...props, filename, method})
  }

}
