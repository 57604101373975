// Copyright (C) 2017-2023 Quasi, Inc. All Rights Reserved.

import {createSlice, createAsyncThunk, isAnyOf} from '@reduxjs/toolkit'

// ** Axios Imports
import Item from '../../../services/Item'
import Company from '../../../services/Company'


export const itemsList = createAsyncThunk('items/list', async (params) => {
  const {companyId, ...query} = params
  const srv = companyId ? new Company(companyId) : Item

//  console.log(srv, params, query)
  const response = await srv.items({params: query})
//  console.log(response.data)
  return {
    params,
    data: response.data.items,
    total: response.data.total
  }
})

export const loadItem = createAsyncThunk('items/load', async id => {
  const response = await new Item(id).get()
  return response.data
})

export const createItem = createAsyncThunk('items/create', async (item, {dispatch, getState, rejectWithValue}) => {
  try {
    const res = await Item.create(item)
    await dispatch(itemsList(getState().items.params))
    return res.data
  } catch (err) {
    console.error('createItem error:', err.response)
    return rejectWithValue(err.response?.data?.msg)
  }
})

export const updateItem = createAsyncThunk('items/update', async (item, {dispatch, getState, rejectWithValue}) => {
  try {
    const {itemId, ...params} = item
    const res = await new Item(itemId).update(params)
    await dispatch(itemsList(getState().items.params))
    //console.log('updateRobot res:', res.data)
    return res.data
  } catch (err) {
    console.error('updateItem error:', err.response)
    return rejectWithValue(err.response?.data?.msg)
  }
})


export const itemsSlice = createSlice({
  name: 'items',
  initialState: {
    data: [],
    total: 0,
    params: {},
    selectedItem: null,
    loading: false,
    editCreateLoading: false,
    selectedLoading: false
  },
  reducers: {
    clearSelection: (state) => {
      state.selectedItem = null
    }
  },
  extraReducers: builder => {
    builder
      .addCase(itemsList.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.total
        state.loading = false
      })
      .addCase(itemsList.pending, (state) => {
        state.loading = true
      })
      .addCase(itemsList.rejected, (state) => {
        state.loading = false
      })
      .addCase(loadItem.fulfilled, (state, action) => {
        state.selectedItem = action.payload
        state.selectedLoading = false
      })
      .addCase(loadItem.pending, (state) => {
        state.selectedLoading = true
      })
      .addCase(loadItem.rejected, (state) => {
        state.loading = false
      })
      .addCase(updateItem.fulfilled, (state, action) => {
        //console.log('pdateRobot.fulfilled', action)
        state.selectedItem = action.payload
      })
      .addMatcher(isAnyOf(
        updateItem.pending,
        createItem.pending
      ), (state) => {
        state.editCreateLoading = true
      })
      .addMatcher(isAnyOf(
        updateItem.rejected,
        createItem.rejected,
        updateItem.fulfilled,
        createItem.fulfilled
      ), (state) => {
        state.editCreateLoading = false
      })
  }
})
export const {
  clearSelection
} = itemsSlice.actions

export default itemsSlice.reducer
